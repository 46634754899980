const sessionStorage = window.sessionStorage

const ACCESS_TOKEN_KEY = "oauth.access_token"
const REFRESH_TOKEN_KEY = "oauth.refresh_token"

export const setSessionToken = (
  access_token: string,
  refresh_token: string,
) => {
  sessionStorage.setItem(ACCESS_TOKEN_KEY, access_token)
  sessionStorage.setItem(REFRESH_TOKEN_KEY, refresh_token)
}

export const getAccessToken = () => {
  return sessionStorage.getItem(ACCESS_TOKEN_KEY) || null
}

export const getRefreshToken = () => {
  return sessionStorage.getItem(REFRESH_TOKEN_KEY) || null
}

export const removeSessionToken = () => {
  sessionStorage.removeItem(ACCESS_TOKEN_KEY)
  sessionStorage.removeItem(REFRESH_TOKEN_KEY)
}
