import { Employee } from "api/freee/employee"
import { UserMe } from "api/freee/user-me"
import { useEffect, useState } from "react"
import { getEmployees, getMe } from "../api/freee"
import { useAuth } from "./useAuth"

export const useFreeeHr = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [me, setMe] = useState<UserMe>()
  const [employees, setEmployees] = useState<Employee[]>([])
  const { accessToken } = useAuth()

  useEffect(() => {
    let unmounted = false
    ;(async () => {
      if (unmounted) {
        return
      }
      if (!accessToken) {
        setMe(undefined)
        setEmployees([])
        return
      }
      const meData = await getMe(accessToken)
      console.log(meData)
      setMe(meData)
      const employeesData = await getEmployees(accessToken, 724104)
      console.log(employeesData)
      setEmployees(employeesData)
      setIsLoading(false)
    })()
    return () => {
      unmounted = true
    }
  }, [accessToken])

  return { me, employees, isLoading }
}
